import React, { FC, ReactNode, useMemo, useState } from "react";
import classnames from "classnames";
import ThemeContext, { ThemeContextProps, classNames as defaultClassNames } from "../context/theme";

type AddClassNameFunction = (classKey: string, className: string) => void;

type Props = {
  children: ReactNode;
  theme?: Partial<ThemeContextProps>;
};

const LayoutSection: FC<Props> = ({ children, theme }) => {
  const [currentTheme, setCurrentTheme] = useState({
    components: theme?.components || {},
    classNames: theme?.classNames || defaultClassNames || {},
  });

  const themeContextValue: ThemeContextProps = useMemo(() => {
    const addClassName: AddClassNameFunction = (classKey, className) => {
      const { classNames } = currentTheme;
      const prevClassName = classNames[classKey] as string;

      if (!prevClassName.includes(className)) {
        setCurrentTheme((prevTheme) => ({
          ...prevTheme,
          classNames: {
            ...prevTheme.classNames,
            [classKey]: classnames(prevClassName, className),
          },
        }));
      }
    };

    return {
      ...currentTheme,
      actions: { addClassName },
    };
  }, [currentTheme]);

  const { classNames } = currentTheme;

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <main className={classnames("main", classNames.main)}>{children}</main>
    </ThemeContext.Provider>
  );
};

export default LayoutSection;
