import classnames from "classnames";
import React from "react";

import { usePlaceholderResolver } from "@frontend/app/hooks";

type Props = {
  className: string;
  merchantName?: string;
  logoUrl: string;
};

const Logo: React.FC<Props> = ({ className, merchantName, logoUrl: baseLogoUrl }) => {
  const placeholderResolver = usePlaceholderResolver();
  const [logoUrl] = placeholderResolver([baseLogoUrl]);

  return logoUrl?.trim() || merchantName ? (
    <div
      aria-label={`${merchantName} logo`}
      className={classnames("logo", className)}
      style={logoUrl ? { backgroundImage: `url(${logoUrl})` } : undefined}
    >
      {!logoUrl ? merchantName : null}
    </div>
  ) : null;
};

export default Logo;
