/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import UrlParse from "url-parse";
import classnames from "classnames";

import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "@frontend/app/store/reducers";
import { TranslationKey, useTranslation } from "@frontend/app/hooks";
import Text from "@frontend/app/components/Text";

const classNameMap = {
  error: "alert--error",
  success: "alert--success",
  warning: "alert--warning",
};

type Props = {
  className?: string;
};

const reasonTranslationMap: Record<string, TranslationKey> = {
  PAYMENT_PROCESSING: "PAYMENT_REDIRECT_PROCESSING",
  CLAIM_NOT_FOUND: "PAYMENT_REDIRECT_ERROR",
  CLAIM_INVALID_STATE: "PAYMENT_REDIRECT_ERROR",
};

const Alert: FC<Props> = ({ className }) => {
  const [messageData, setMessageData] = useState<{ className: string; message: TranslationKey } | null>(null);

  const onClickHandler = () => {
    setMessageData(null);
    updateQueryString();
  };

  const { payableStatus } = useSelector(mapState, shallowEqual);
  const translation = useTranslation();

  useEffect(() => {
    if (!payableStatus) {
      return;
    }

    const { result: isPayable, reason } = payableStatus;
    if (!isPayable) {
      const translationKey = reasonTranslationMap[reason];
      if (!translation(translationKey)) {
        return;
      }

      setMessageData({
        className: translationKey === "PAYMENT_REDIRECT_PROCESSING" ? classNameMap.warning : classNameMap.error,
        message: translationKey,
      });
    }
  }, [payableStatus]);

  useEffect(() => {
    function onHashChange() {
      const { message, type } = UrlParse(window.location.href, true).query;
      if (!message || !type) {
        return;
      }

      if (!translation(message)) {
        return;
      }

      setMessageData({ message, className: classNameMap[type] || classNameMap.warning });
    }

    onHashChange();
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  if (!messageData) {
    return null;
  }

  return (
    <div className={classnames("alert", className, messageData.className)}>
      <div className="alert__section">
        <p className="alert__message">
          <Text translationKey={messageData.message} />
        </p>
        <button className="alert__close" type="button" onClick={onClickHandler}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;

function updateQueryString() {
  const url = UrlParse(window.location.href, true);
  const queryData = url.query;
  delete queryData.message;
  delete queryData.type;
  url.set("query", queryData);

  const newUrl = UrlParse(url.toString());

  const { history = {} as any } = window;
  if (!history.pushState) {
    window.location.search = newUrl.query;
    return;
  }

  history.pushState({}, "", newUrl.toString());
}

function mapState({ viewData: { payableStatus } }: RootState) {
  return { payableStatus };
}

Alert.defaultProps = {
  className: "",
};
